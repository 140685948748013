import { createRouter, createWebHistory } from 'vue-router'
import Client from '../views/pages/Clients.vue';
import Admin from '../views/admin/Admin.vue';

const routes = [
  {
    path: "/",
    component: Client,
    redirect: "/home",
    children: [
      { path: "home", name: "home", component: () => import(/* webpackChunkName: "home" */ '../views/pages/Home.vue') },
      { path: 'about', name: 'about', component: () => import(/* webpackChunkName: "about" */ '../views/pages/About.vue')},
      { path: 'gallery', name: 'gallery', component: () => import(/* webpackChunkName: "gallery" */ '../views/pages/Gallery.vue')},
      { path: 'partner', name: 'partner', component: () => import(/* webpackChunkName: "partner" */ '../views/pages/Partner.vue')},
      { path: "product", name: "product", component: () => import(/* webpackChunkName: "product" */ '../views/pages/Product.vue') },
      { path: "product/:id", name: "ProductDetails", component: () => import(/* webpackChunkName: "productdetails" */ '../views/services/ProductDetails.vue') },
      { path: 'contactus', name: 'contactus', component: () => import(/* webpackChunkName: "contactus" */ '../views/pages/Contactus.vue')},
      { path: 'login', name: 'login', component: () => import(/* webpackChunkName: "login" */ '../views/pages/Login.vue')},



      { path: 'poultry', name: 'poultry', component: () => import(/* webpackChunkName: "poultry" */ '../views/subpages/Poultry.vue')},
      { path: 'ruminent', name: 'ruminent', component: () => import(/* webpackChunkName: "ruminent" */ '../views/subpages/Ruminent.vue')},
      { path: 'aqua', name: 'aqua', component: () => import(/* webpackChunkName: "aqua" */ '../views/subpages/Aqua.vue')},


      { path: 'afier', name: 'afier', component: () => import(/* webpackChunkName: "afier" */ '../views/sub/Acidifier.vue')},
      { path: 'ktonic', name: 'ktonic', component: () => import(/* webpackChunkName: "ktonic" */ '../views/sub/KidneyTonic.vue')},
      { path: 'ltonic', name: 'ltonic', component: () => import(/* webpackChunkName: "ltonic" */ '../views/sub/LiverTonic.vue')},
      { path: 'tbinder', name: 'tbinder', component: () => import(/* webpackChunkName: "tbinder" */ '../views/sub/Toxinbinder.vue')},

      { path: 'aprobiotic', name: 'aprobiotic', component: () => import(/* webpackChunkName: "aprobiotic" */ '../views/sub/Aprobiotic.vue')},
      { path: 'others', name: 'others', component: () => import(/* webpackChunkName: "others" */ '../views/sub/Others.vue')},
      { path: 'pothers', name: 'othersp', component: () => import(/* webpackChunkName: "othersp" */ '../views/sub/Othersp.vue')},
      { path: 'rothers', name: 'othersr', component: () => import(/* webpackChunkName: "othersr" */ '../views/sub/Othersr.vue')},
      

      { path: 'lcalcium', name: 'lcalcium', component: () => import(/* webpackChunkName: "lcalcium" */ '../views/sub/LiquidCalcium.vue')},
      { path: 'rltonic', name: 'rltonic', component: () => import(/* webpackChunkName: "rltonic" */ '../views/sub/RliverTonic.vue')},
      { path: 'mbolus', name: 'mbolus', component: () => import(/* webpackChunkName: "mbolus" */ '../views/sub/MineraBolus.vue')},
      { path: 'pbolus', name: 'pbolus', component: () => import(/* webpackChunkName: "pbolus" */ '../views/sub/ProbioticBolus.vue')},

      { path: 'mrvet', name: 'mrvet', component: () => import(/* webpackChunkName: "mrvet" */ '../views/subcategory/Mrvet.vue')},
      { path: 'vproducts', name: 'vproducts', component: () => import(/* webpackChunkName: "vproducts" */ '../views/subcategory/Vetproducts.vue')},
      { path: 'bdevelopment', name: 'bdevelopment', component: () => import(/* webpackChunkName: "bdevelopment" */ '../views/subcategory/Bdevelopment.vue')},
      { path: 'manalysis', name: 'manalysis', component: () => import(/* webpackChunkName: "manalysis" */ '../views/subcategory/Manalysis.vue')},
      { path: 'rguidance', name: 'rguidance', component: () => import(/* webpackChunkName: "rguidance" */ '../views/subcategory/Rguidance.vue')},
    ]
  },

  {
    path: "/admin",
    component: Admin,
    redirect: "/admin/dashboard",
    meta: { authOnly: true },
    children: [
      { path: 'dashboard', name: 'dashboard', component: () => import(/* webpackChunkName: "dashboard" */ '../views/admin/Dashboard.vue')},
      { path: 'user', name: 'user', component: () => import(/* webpackChunkName: "user" */ '../views/admin/Users.vue')},
      { path: 'products', name: 'products', component: () => import(/* webpackChunkName: "products" */ '../views/admin/Products.vue')},
      { path: 'sliders', name: 'sliders', component: () => import(/* webpackChunkName: "sliders" */ '../views/admin/Sliders.vue')},
      { path: 'gallerys', name: 'gallerys', component: () => import(/* webpackChunkName: "gallerys" */ '../views/admin/Gallerys.vue')},
      { path: 'partners', name: 'partners', component: () => import(/* webpackChunkName: "partners" */ '../views/admin/Partners.vue')}
    ],
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

function isLoggedIn() {
  return localStorage.getItem("token");
}


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {

    if (isLoggedIn()) {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
