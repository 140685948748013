<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script> 

<style scoped>
#app {
  background: rgb(238, 234, 234); 
}

</style>

