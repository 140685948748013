<template>
  <div class="footer">
    <div class="row">
      <div class="col-md-4">
        <h4>GreenVetPharma</h4>
        <div class="info">
          <p>
            GreenVetPharma is a consultation distribution and import company in
            the veterinary sector of Bangladesh. We are serving with the leading
            pharmaceuticals companies in Bangladesh from 2015.
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <h4>Quick Link</h4>
        <div class="info1">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/product">Product</router-link></li>
            <li><router-link to="/partner">Partners</router-link></li>
            <li><router-link to="/contactus">Contact Us</router-link></li>
            <li><router-link to="/login">Login</router-link></li>
          </ul>
        </div>
      </div>

      <div class="col-md-4">
        <h4>Head Office</h4>
        <div class="info2">
          <address>
            House: 66,&nbsp;Road: 11,<br />
            DIT-Project,Merul-Badda,<br />
            Dhaka,Bangladesh.<br />
            Email: gvetpharma@gmail.com<br />
            Mobile: +8801917114636
          </address>
        </div>
      </div>
    </div>
    <div class="row2">
      <p>All rights reserved in &copy; Ultrawave.Digital</p>
    </div>
  </div>
</template>

<style scoped>
.footer {
  width: 100%;
  height: 100%;
  background: #12c3f3;
  padding: 0;
  margin: 0;
}
.footer .row {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  margin: 0;
}
.row .col-md-4 {
  width: 33%;
  height: 100%;
  padding: 10px;
  margin: 0;
  display: block;
}
.row .col-md-4 h4 {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Bree Serif", serif;
  text-align: center;
  padding: 10px;
  margin: 0;
}
.row .col-md-4 .info,
.row .col-md-4 .info1,
.row .col-md-4 .info2 {
  width: 100%;
  height: auto;
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
}
.row .col-md-4 .info p {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "PT Sans", sans-serif;
  text-align: center;
  padding: 20px;
  margin: 0;
}
.col-md-4 .info1 ul {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: block;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
.col-md-4 .info1 ul li {
  list-style: none;
  display: flex;
  justify-content: center;
}
.col-md-4 .info1 ul li a {
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  font-family: "PT Sans", sans-serif;
      display: flex;
    justify-content: center;
}

.row .col-md-4 .info2 address {
  width: 100%;
  padding: 10px;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "PT Sans", sans-serif;
  display: flex;
  justify-content: center;
}
.footer .row2 {
  width: 100%;
  height: 40px;
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: center;
  background: #fff;
}
.footer .row2 p {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
  font-family: "Roboto Slab", serif;
}

@media only screen and (max-width: 436px) {
  .footer {
    width: 100%;
    height: 100%;
background: #cc3165fb;
    padding: 0;
    margin: 0;
  }
  .footer .row {
    width: 100%;
    height: 100%;
    display: block;
    padding: 0;
    margin: 0;
  }
  .row .col-md-4 {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0;
    display: block;
  }
  .row .col-md-4 h4 {
    font-size: 1.8rem;
    font-weight: 600;
    font-family: "Bree Serif", serif;
    text-align: center;
    padding: 5px;
    margin: 0;
  }
  .row .col-md-4 .info,
  .row .col-md-4 .info1,
  .row .col-md-4 .info2 {
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .row .col-md-4 .info p {
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "PT Sans", sans-serif;
    text-align: center;
    padding: 20px;
    margin: 0;
  }
  .col-md-4 .info1 ul {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: block;
    border-left: none;
    border-right: none;
  }
  .col-md-4 .info1 ul li {
    list-style: none;
    display: flex;
    justify-content: center;
  }
  .col-md-4 .info1 ul li a {
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    font-family: "PT Sans", sans-serif;
    display: flex;
    justify-content: center;
  }

  .row .col-md-4 .info2 address {
    width: 100%;
    padding: 10px;
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "PT Sans", sans-serif;
    text-align: center;
    display: flex;
  }
  .footer .row2 {
    width: 100%;
    height: 40px;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: center;
    background: #fff;
  }
  .footer .row2 p {
    font-size: 0.9rem;
    font-weight: 400;
    text-align: center;
    font-family: "Roboto Slab", serif;
  }
}
</style>