<template>
  <div class="navigation">
    <div class="full">
      <nav>
        <a class="navbar-brand" href="#">
          <img src="../../assets/n_logo.svg" />
        </a>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/gallery">Gallery</router-link></li>
          <li>
            <router-link to="/product">Product</router-link>
            <ul class="ul">
              <li><router-link to="/poultry">Poultry</router-link></li>
              <li><router-link to="/ruminent">Ruminant</router-link></li>
              <li><router-link to="/aqua">Aqua</router-link></li>
            </ul>
          </li>
          <li><router-link to="/partner">Partners</router-link></li>
          <li><router-link to="/contactus">Contact Us</router-link></li>
        </ul>
      </nav>
    </div>
    <div class="mini">
      <nav>
        <a class="brand">
          <div class="image">
            <img src="../../assets/n_logo.svg" />
          </div>

          <div class="btn" @click="onClick()">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </div>
        </a>
        <ul>
          <transition-group name="slide-fade">
            <li v-if="!show">
              <router-link to="/" @click="onClick()">Home</router-link>
            </li>
            <li v-if="!show">
              <router-link to="/about" @click="onClick()">About</router-link>
            </li>
            <li v-if="!show">
              <router-link to="/gallery" @click="onClick()"
                >Gallery</router-link
              >
            </li>
            <li v-if="!show">
              <router-link to="/product">Product</router-link>
              <ul class="ul">
                <li v-if="!show">
                  <router-link to="/poultry" @click="onClick()"
                    >Poultry</router-link
                  >
                </li>
                <li v-if="!show">
                  <router-link to="/ruminent" @click="onClick()"
                    >Ruminant</router-link
                  >
                </li>
                <li v-if="!show">
                  <router-link to="/aqua" @click="onClick()">Aqua</router-link>
                </li>
              </ul>
            </li>
            <li v-if="!show">
              <router-link to="/partner" @click="onClick()"
                >Partners</router-link
              >
            </li>
            <li v-if="!show">
              <router-link to="/contactus" @click="onClick()"
                >Contact Us</router-link
              >
            </li>
          </transition-group>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },

  created() {},

  methods: {
    onClick() {
      this.show = !this.show;
      if (this.showSidebar) {
        this.showLink = true;
        setTimeout(() => {
          this.showSidebar = false;
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Montserrat+Alternates:wght@700&family=PT+Sans:ital@0;1&family=Roboto:wght@700&family=Source+Sans+Pro&family=Space+Mono&display=swap");

.navigation {
  padding: 0;
  margin: 0;
  width: 100%;
  position: fixed;
  z-index: 99;
}
.navigation .full {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.full nav {
  display: flex;
  flex-direction: space-around;
  width: 100%;
  height: 130px;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 2px 2px 5px #12c3f3;
}
.full nav a {
  height: 100%;
  padding: 5px;
  margin: 5px;
}
.full nav a img {
  width: 100%;
  height: 100%;
  padding: 5px;
  margin: 0;
}
.full nav ul {
  width: 70%;
  height: 100%;
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: right;
}
.full nav ul li {
  padding: 10px;
  margin-top: 50px;
  list-style: none;
}
.full nav ul li a {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  font-family: "Space Mono", monospace;
}
.full nav ul li a:hover,
a.router-link-exact-active {
  color: green;
}
.full ul li:hover .ul {
  display: block;
  padding: 0;
  margin: 0;
}
.full ul li .ul {
  width: 120px;
  height: 150px;
  position: absolute;
  top: 100px;
  display: none;
  z-index: 1;
  background: #fff;
  padding: 0;
  margin: 0;
}
.full ul li .ul li {
  width: 100%;
  height: 50px;
  cursor: pointer;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.full ul li .ul li a {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  font-family: "Space Mono", monospace;
}
.full ul li .ul li:hover {
  /* margin-left: -1px; */
  background: #12c2f39d;
}
.navigation .mini {
  display: none;
}
@media only screen and (max-width: 436px) {
  .navigation {
    padding: 0;
    margin: 0;
    width: 100%;
    position: fixed;
    z-index: 99;
  }
  .navigation .full {
    display: none;
  }
  .navigation .mini {
    width: 100%;
    height: 120px;
    padding: 0;
    margin: 0;
    display: block;
  }
  .mini nav {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    /* background: yellow; */
    background: #cc3165fb;
    box-shadow: 0 0 10px green;
  }

  .mini nav .brand {
    width: 100%;
    height: 100%;
    padding: 5px;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .mini nav .brand .image {
    width: 55%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .mini nav .brand .image img {
    width: 240px;
    height: 110px;
    padding: 0;
    margin: 0;
  }
  .mini nav .brand .btn {
    width: 45%;
    height: 100%;
    padding: 20px;
    margin: 0;
  }
  .mini nav .brand .btn i {
    font-size: 2.2rem;
    font-weight: 700;
    color: green;
    padding: 10px;
    margin-left: 100px;
  }

  .mini nav ul {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  .mini nav ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    background: rgba(255, 255, 0, 0.842);
    line-height: 50px;
  }
  .mini nav ul li a {
    text-decoration: none;
    font-family: "Space Mono", monospace;
  }

  nav ul li a:hover,
  nav ul li a.router-link-exact-active {
    border-bottom: none;
  }
  .mini nav ul li:hover .ul {
    position: relative;
    display: block;
    /* visibility: visible; */
    width: 100%;
  }
  .mini ul li .ul {
    display: none;
    /* visibility: hidden; */
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .mini ul li .ul li {
    list-style: none;
    padding: 0;
    margin: 0;
    background: rgba(255, 255, 0, 0.863);
    line-height: 50px;
    width: 100%;
  }
  .mini ul li .ul li:hover,
  .mini ul li:hover {
    background: rgba(170, 23, 23, 0.664);
  }
  .mini ul li a,
  .mini ul li .ul li a {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Space Mono", monospace;
    color: #000;
    text-align: center;
    padding: 10px;
    margin: 5px;
    border-bottom: 2px none #fff;
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-in;
  }
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(10%);
    opacity: 0;
  }
}
</style>